import Cropper from 'cropperjs';
import { ToastUX } from './ToastUX';
import { Utilities } from '../common/Utilities';
import { ImageService } from '../api/services/ImageService';
import { DropArea } from './DropArea';
export var ImageTools;
(function (ImageTools) {
    const toast = ToastUX;
    const debounce = Utilities.debounce;
    const warningWith = 2080;
    const warningHeight = 1170;
    let dropArea;
    let imageArea;
    let uriTargetInput;
    let webObjectIdInput;
    let sourceFileNameInput;
    let image;
    let cropper;
    function handleFile(file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            if (sourceFileNameInput instanceof HTMLInputElement) {
                sourceFileNameInput.value = file.name;
            }
            setupViewForImageCropping(reader.result);
        };
    }
    function setCroppedImageWarningCondition(warnHoriz, warnVert) {
        const horizOpacity = warnHoriz ? 1 : 0;
        const vertOpacity = warnVert ? 1 : 0;
        var horizElement = document.getElementById('warning-horizontal-bars');
        var vertElement = document.getElementById('warning-vertical-bars');
        horizElement.style.opacity = horizOpacity.toString();
        vertElement.style.opacity = vertOpacity.toString();
    }
    function handleCropChangeEvent(event) {
        const cropWidth = event.detail.width;
        const cropHeight = event.detail.height;
        const vertWarning = warningWith > cropWidth;
        const horizWarning = warningHeight > cropHeight;
        debounce(function (event) {
            setCroppedImageWarningCondition(horizWarning, vertWarning);
        }, 100)(event);
    }
    function setupViewForImageCropping(imageSource) {
        if (dropArea instanceof HTMLElement) {
            dropArea.classList.add('d-none');
        }
        if (imageArea instanceof HTMLElement) {
            imageArea.classList.remove('d-none');
        }
        if (image instanceof HTMLImageElement) {
            image.src = imageSource;
        }
        if (cropper instanceof Cropper) {
            cropper.replace(imageSource);
        }
        else {
            cropper = new Cropper(image, {
                aspectRatio: 16 / 9,
                minContainerWidth: 1100,
                minContainerHeight: 800,
                crop(event) {
                    handleCropChangeEvent(event);
                },
            });
        }
    }
    function setupViewForFileInput() {
        if (dropArea instanceof HTMLElement) {
            dropArea.classList.remove('d-none');
        }
        if (imageArea instanceof HTMLElement) {
            imageArea.classList.add('d-none');
        }
        if (cropper instanceof Cropper) {
            cropper.destroy();
        }
        if (image instanceof HTMLImageElement) {
            image.src = '';
        }
        if (sourceFileNameInput instanceof HTMLInputElement) {
            sourceFileNameInput.value = '';
        }
    }
    function ResetTool() {
        setupViewForFileInput();
    }
    ImageTools.ResetTool = ResetTool;
    function ResetImage() {
        if (cropper instanceof Cropper) {
            cropper.reset();
        }
    }
    ImageTools.ResetImage = ResetImage;
    function SendCroppedImage() {
        if (cropper instanceof Cropper
            && webObjectIdInput instanceof HTMLInputElement
            && uriTargetInput instanceof HTMLInputElement
            && sourceFileNameInput instanceof HTMLInputElement) {
            let url = uriTargetInput.value;
            let formData = new FormData();
            cropper.getCroppedCanvas({
                minWidth: 2080,
                minHeight: 1170,
                maxWidth: 4096,
                maxHeight: 4096,
                fillColor: '#FFFFFF'
            }).toBlob((blob) => {
                formData.append('ImageFiles', blob, sourceFileNameInput.value);
                formData.append('EntityId', webObjectIdInput.value);
                ImageService.SendImageFormData(url, formData);
            }, 'image/jpeg', 1.0);
        }
    }
    ImageTools.SendCroppedImage = SendCroppedImage;
    function DownloadCroppedImage() {
        if (cropper instanceof Cropper
            && sourceFileNameInput instanceof HTMLInputElement) {
            let url = cropper.getCroppedCanvas()
                .toDataURL('image/jpeg', 1.0);
            let a = document.createElement('a');
            a.href = url;
            a.download = sourceFileNameInput.value;
            a.click();
            a.remove();
        }
    }
    ImageTools.DownloadCroppedImage = DownloadCroppedImage;
    function Initialize() {
        image = document.getElementById('resize-image');
        imageArea = document.getElementById('my-image-area');
        webObjectIdInput = document.getElementById('image-webobject-id');
        uriTargetInput = document.getElementById('image-destination-uri');
        sourceFileNameInput = document.getElementById('image-source-file-name');
        setupViewForFileInput();
        dropArea = document.getElementById('drop-area');
        DropArea.Initialize(handleFile, dropArea);
    }
    ImageTools.Initialize = Initialize;
})(ImageTools || (ImageTools = {}));
