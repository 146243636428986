var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var ImageService;
(function (ImageService) {
    function DeleteImage(elem) {
        const yes = confirm('Permanently delete this image?');
        if (yes) {
            doDelete(elem);
        }
    }
    ImageService.DeleteImage = DeleteImage;
    function doDelete(e) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const el = e;
            const uri = (_a = el.getAttribute('data-actionuri')) !== null && _a !== void 0 ? _a : '';
            const response = yield fetch(uri.toString(), {
                method: 'DELETE'
            }).then(res => {
                if (res.ok) {
                    location.reload();
                }
            });
        });
    }
    function ImageImport(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const uploadUri = e.getAttribute('data-upload-uri');
            const webObjectId = e.getAttribute('data-webobject-id');
            if (uploadUri.length > 0 && webObjectId.length > 0) {
                const uriElement = document.getElementById('image-destination-uri');
                const webObjectIdElement = document.getElementById('image-webobject-id');
                if (uriElement instanceof HTMLInputElement && webObjectIdElement instanceof HTMLInputElement) {
                    uriElement.value = uploadUri;
                    webObjectIdElement.value = webObjectId;
                }
            }
        });
    }
    ImageService.ImageImport = ImageImport;
    function SendImageFormData(url, formData) {
        return __awaiter(this, void 0, void 0, function* () {
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => {
                location.reload();
            })
                .catch(() => {
            });
        });
    }
    ImageService.SendImageFormData = SendImageFormData;
    function SendImage(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e.target instanceof HTMLInputElement) {
                const formElement = e.target.form;
                const formData = new FormData(formElement);
                const response = yield fetch(formElement.action, {
                    method: 'POST',
                    body: formData
                });
                location.reload();
            }
        });
    }
    ImageService.SendImage = SendImage;
})(ImageService || (ImageService = {}));
