import { map, latLng, marker, tileLayer } from "leaflet";
export var MapService;
(function (MapService) {
    function Initialize() {
        const inputLat = document.getElementById('map-lat');
        const inputLon = document.getElementById('map-lon');
        const inputZoom = document.getElementById('map-zoom');
        const inputPinTitle = document.getElementById('map-pin-title');
        const mapHost = document.getElementById('map-host');
        const mapView = document.getElementById('map-view');
        if (inputLat instanceof HTMLInputElement
            && inputLon instanceof HTMLInputElement
            && inputZoom instanceof HTMLInputElement
            && inputPinTitle instanceof HTMLInputElement
            && mapHost instanceof HTMLDivElement
            && mapView instanceof HTMLDivElement) {
            if (mapHost) {
                const rect = mapHost.getBoundingClientRect();
                mapView.style.height = rect.height - 2 + 'px';
                mapView.style.width = rect.width - 2 + 'px';
            }
            const lat = parseFloat(inputLat.value);
            const lon = parseFloat(inputLon.value);
            const zoom = parseFloat(inputZoom.value);
            const pinTitle = inputPinTitle.value;
            const latlon = latLng(lat, lon);
            const mapOptions = {
                center: latlon,
                zoom: zoom,
                attributionControl: false
            };
            const geomap = map(mapView, mapOptions);
            let layer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
            const markerOptions = {
                title: pinTitle
            };
            const pin = marker(mapOptions.center, markerOptions);
            layer.addTo(geomap);
            pin.addTo(geomap);
        }
    }
    MapService.Initialize = Initialize;
})(MapService || (MapService = {}));
