export var CompanyService;
(function (CompanyService) {
    function UpdateOperatingHours(formId) {
        var form = document.getElementById(formId);
        if (form instanceof HTMLFormElement) {
            const formData = new FormData(form);
            const response = fetch(form.action, {
                method: 'PUT',
                body: formData
            }).then(res => {
                if (res.ok) {
                    location.reload();
                }
            });
        }
    }
    CompanyService.UpdateOperatingHours = UpdateOperatingHours;
})(CompanyService || (CompanyService = {}));
