var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as employee from '../../models/company/EmployeeModels';
import { Offcanvas } from '../../mdb-ui-kit-cherry-pick';
export var EmployeeService;
(function (EmployeeService) {
    function shiftEmployeeRole(roles, roleEnumId, form) {
        const check = form.querySelector('#' + roleEnumId);
        if (check instanceof HTMLInputElement && check.checked) {
            roles = Number(check.value);
        }
        return roles;
    }
    function shiftDistributionGroup(roles, groupEnumId, form) {
        const check = form.querySelector('#' + groupEnumId + 'Group');
        console.log(groupEnumId);
        console.log(check.checked);
        if (check instanceof HTMLInputElement && check.checked) {
            roles = Number(check.value);
        }
        return roles;
    }
    function buildEmployeeRolesFlag(form) {
        let roles = employee.EmployeeRoles.None;
        if (form instanceof HTMLFormElement) {
            getEmployeeRolesInputKeys()
                .forEach(id => roles |= shiftEmployeeRole(roles, id, form));
        }
        return roles;
    }
    function buildDistributionGroupFlag(form) {
        let roles = employee.DistributionGroups.None;
        console.log('building group flag');
        console.log('roles: ' + roles);
        if (form instanceof HTMLFormElement) {
            getDistributionGroupsInputKeys()
                .forEach(id => roles |= shiftDistributionGroup(roles, id, form));
        }
        console.log('roles: ' + roles);
        return roles;
    }
    function employeeIsInRole(employeeRoles, roleCheck) {
        let employeeRoleEval = employeeRoles & roleCheck;
        return employeeRoleEval == roleCheck;
    }
    function employeeIsInGroup(employeeGroups, groupCheck) {
        let employeeGroupEval = employeeGroups & groupCheck;
        return employeeGroupEval == groupCheck;
    }
    function getEmployeeRolesInputKeys() {
        var keys = Object.keys(employee.EmployeeRoles)
            .filter((v) => isNaN(v) && v != 'None');
        return keys;
    }
    function getDistributionGroupsInputKeys() {
        var keys = Object.keys(employee.DistributionGroups)
            .filter((v) => isNaN(v) && v != 'None');
        return keys;
    }
    function mapEmployeeResponseToForm(response, form) {
        return __awaiter(this, void 0, void 0, function* () {
            if (form instanceof HTMLFormElement) {
                form.querySelector('#Id').value = response.id;
                form.querySelector('#Title').value = response.title;
                //(form.querySelector('#Blurb') as HTMLInputElement).value = response.blurb;
                form.querySelector('#Email').value = response.email;
                form.querySelector('#Name').value = response.name;
                form.querySelector('#PrimaryPhone').value = response.primaryPhone;
                form.querySelector('#MobilePhone').value = response.mobilePhone;
                form.querySelector('#Active').checked = response.active;
                form.querySelector('#HideEmail').checked = response.hideEmail;
                //(form.querySelector('#UseFemaleAvatar') as HTMLInputElement).checked = response.useFemaleAvatar;
                form.querySelector('img').src = response.imageThumbnail;
                form.querySelector('#Sales').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.Sales);
                form.querySelector('#Inside').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.Inside);
                form.querySelector('#Outside').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.Outside);
                form.querySelector('#Parts').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.Parts);
                form.querySelector('#Service').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.Service);
                form.querySelector('#GeneralManager').checked = employeeIsInRole(response.employeeRoles, employee.EmployeeRoles.GeneralManager);
                form.querySelector('#SalesGroup').checked = employeeIsInGroup(response.distributionGroups, employee.DistributionGroups.Sales);
                form.querySelector('#PartsGroup').checked = employeeIsInGroup(response.distributionGroups, employee.DistributionGroups.Parts);
                form.querySelector('#ServiceGroup').checked = employeeIsInGroup(response.distributionGroups, employee.DistributionGroups.Service);
                return true;
            }
            return false;
        });
    }
    const apiEmployeeGet = (employeeId) => fetch("/employee/" + employeeId).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
    const apiEmployeeUpsert = (request) => fetch("/employee/", {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
    function LoadEmployeeEditor(element) {
        return __awaiter(this, void 0, void 0, function* () {
            if (element instanceof HTMLElement) {
                const employeeId = element.getAttribute('data-employeeId');
                const editorId = element.getAttribute('data-editorId');
                const editor = document.getElementById(editorId);
                if (editor instanceof HTMLElement) {
                    const form = editor.querySelector('form');
                    if (form instanceof HTMLFormElement) {
                        apiEmployeeGet(employeeId)
                            .then(response => mapEmployeeResponseToForm(response, form)
                            .then(formMapCompleted => {
                            if (formMapCompleted) {
                                const offcanvas = Offcanvas.getOrCreateInstance(editor);
                                offcanvas.toggle();
                            }
                        }));
                    }
                }
            }
        });
    }
    EmployeeService.LoadEmployeeEditor = LoadEmployeeEditor;
    function UpsertEmployee(formElementId) {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById(formElementId);
            if (form instanceof HTMLFormElement) {
                const request = {
                    id: form.querySelector('#Id').value,
                    title: form.querySelector('#Title').value,
                    email: form.querySelector('#Email').value,
                    name: form.querySelector('#Name').value,
                    primaryPhone: form.querySelector('#PrimaryPhone').value,
                    mobilePhone: form.querySelector('#MobilePhone').value,
                    active: Boolean(form.querySelector('#Active').checked),
                    hideEmail: Boolean(form.querySelector('#HideEmail').checked),
                    useFemaleAvatar: false, // Boolean((form.querySelector('#UseFemaleAvatar') as HTMLInputElement).checked),
                    employeeRoles: buildEmployeeRolesFlag(form),
                    distributionGroups: buildDistributionGroupFlag(form)
                };
                apiEmployeeUpsert(request)
                    .then(result => location.reload());
            }
        });
    }
    EmployeeService.UpsertEmployee = UpsertEmployee;
})(EmployeeService || (EmployeeService = {}));
