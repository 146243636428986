var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastUX } from '../../ux/ToastUX';
import { CarouselUX } from '../../ux/CarouselUX';
export var InlineEditService;
(function (InlineEditService) {
    var sortable = require('sortablejs').Sortable;
    const editTrackingRequest = {
        value: 'i <3 iggy pop'
    };
    const toast = ToastUX;
    let thumbsSortElement;
    let webObjectSortElement;
    InlineEditService.Events = {
        focus: 'focus',
        blur: 'blur',
        keyPress: 'keypress',
        keyDown: 'keydown'
    };
    InlineEditService.Attributes = {
        contentEditable: 'contentEditable',
        editing: 'editing'
    };
    InlineEditService.CssClasses = {
        inEditMode: 'in-edit-mode'
    };
    InlineEditService.Elements = {
        authEditable: 'auth-editable:not([data-action])'
    };
    function onFocus(e) {
        if (e.target instanceof Element) {
            editTrackingRequest.value = e.target.innerHTML;
        }
    }
    InlineEditService.onFocus = onFocus;
    function onBlur(e) {
        if (e.target instanceof Element) {
            let currentValue = e.target.innerHTML;
            if (editTrackingRequest.value !== currentValue) {
                editTrackingRequest.value = currentValue;
            }
        }
    }
    InlineEditService.onBlur = onBlur;
    function onKeyPress(e) {
        if (e.target instanceof HTMLElement && e.key === 'Enter') {
            if (!e.getModifierState('Shift')) {
                e.target.blur();
            }
        }
    }
    InlineEditService.onKeyPress = onKeyPress;
    function onKeyDown(e) {
        if (e.target instanceof HTMLElement && e.key === 'Escape') {
            e.target.innerHTML = editTrackingRequest.value;
            e.target.blur();
        }
    }
    InlineEditService.onKeyDown = onKeyDown;
    function Initialize(targetControlId) {
        const editControl = document.getElementById(targetControlId);
        if (editControl == undefined || editControl == null) {
            sessionStorage.removeItem('uhe-web-editing');
            return;
        }
        editControl.onclick = ToggleEditMode;
        let sessionEditing = sessionStorage.getItem('uhe-web-editing');
        if (sessionEditing == 'on') {
            editControl.click();
        }
    }
    InlineEditService.Initialize = Initialize;
    function ToggleEditMode(e) {
        const evt = InlineEditService.Events;
        const attr = InlineEditService.Attributes;
        const elems = InlineEditService.Elements;
        const cls = InlineEditService.CssClasses;
        let target = e.target;
        if (target instanceof HTMLElement) {
            const auth_edits = Array.from(document.querySelectorAll(elems.authEditable));
            let editing = target.getAttribute(attr.editing);
            if (!!editing) {
                sessionStorage.removeItem('uhe-web-editing');
                document.body.classList.remove(cls.inEditMode);
                target.removeAttribute(attr.editing);
                auth_edits.forEach(x => {
                    x.removeEventListener(evt.blur, HandleInlineEdit);
                    x.removeEventListener(evt.focus, onFocus);
                    if (x instanceof HTMLElement) {
                        x.removeEventListener(evt.keyPress, onKeyPress);
                        x.removeEventListener(evt.keyDown, onKeyDown);
                    }
                    x.removeAttribute(attr.contentEditable);
                });
                CarouselUX.PlayCarousel();
                AllowSortingOfCarouselIndicators(false);
                AllowSortingWebObjects(false);
                location.reload();
            }
            else {
                sessionStorage.setItem('uhe-web-editing', 'on');
                document.body.classList.add(cls.inEditMode);
                target.setAttribute(attr.editing, 'true');
                auth_edits.forEach(x => {
                    x.addEventListener(evt.blur, HandleInlineEdit);
                    x.addEventListener(evt.focus, onFocus);
                    if (x instanceof HTMLElement) {
                        x.addEventListener(evt.keyPress, onKeyPress);
                        x.addEventListener(evt.keyDown, onKeyDown);
                    }
                    x.setAttribute(attr.contentEditable, 'true');
                });
                CarouselUX.PauseCarousel();
                AllowSortingOfCarouselIndicators(true);
                AllowSortingWebObjects(true);
            }
        }
    }
    InlineEditService.ToggleEditMode = ToggleEditMode;
    function webObjectSortEnded(evt) {
        return __awaiter(this, void 0, void 0, function* () {
            var el = evt.item;
            if (el == undefined || el == null) {
                return;
            }
            var parent = el.parentNode;
            if (parent == undefined || parent == null) {
                return;
            }
            const authEdits = parent.querySelectorAll('[sort-item]');
            const request = {
                ordinals: {}
            };
            let iter = 1;
            authEdits.forEach(x => {
                const id = x.getAttribute('data-id');
                request.ordinals[id] = iter;
                iter++;
            });
            const response = yield fetch('/webobject', {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(request)
            }).then(res => {
                if (res.ok) {
                    //location.reload();
                }
            });
        });
    }
    function sortEnded(evt) {
        return __awaiter(this, void 0, void 0, function* () {
            var el = document.getElementById('uhe-carousel-indicators');
            if (el == undefined || el == null) {
                return;
            }
            const authEdits = el.querySelectorAll('auth-editable');
            const request = {
                ordinals: {}
            };
            let iter = 1;
            authEdits.forEach(x => {
                const id = x.getAttribute('data-id');
                request.ordinals[id] = iter;
                iter++;
            });
            const response = yield fetch('/image', {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(request)
            }).then(res => {
                if (res.ok) {
                    location.reload();
                }
            });
        });
    }
    function AllowSortingOfCarouselIndicators(allowSort = true) {
        if (thumbsSortElement) {
            thumbsSortElement.option('sort', allowSort);
        }
        else {
            var el = document.getElementById('uhe-carousel-indicators');
            if (el == undefined || el == null) {
                return;
            }
            thumbsSortElement = new sortable.create(el, {
                'group': 'thumbs',
                'sort': allowSort,
                'animation': 150,
                'onEnd': sortEnded
            });
        }
    }
    function AllowSortingWebObjects(allowSort = true) {
        if (webObjectSortElement) {
            webObjectSortElement.option('sort', allowSort);
        }
        else {
            var el = document.getElementById('sortable-webobjects');
            if (el == undefined || el == null) {
                return;
            }
            webObjectSortElement = new sortable.create(el, {
                'group': 'webobjects',
                'sort': allowSort,
                'animation': 150,
                'onEnd': webObjectSortEnded
            });
        }
    }
    function HandleInlineEdit(e) {
        if (e.target instanceof HTMLElement) {
            let currentValue = e.target.innerHTML;
            if (currentValue.length === 0) {
                e.target.innerHTML = editTrackingRequest.value;
                toast.GiveErrorToast('Value cannot be empty here.');
                return;
            }
            if (editTrackingRequest.value !== currentValue) {
                const request = BuildInlineEditRequest(e.target, currentValue);
                PostData(request).then((res) => __awaiter(this, void 0, void 0, function* () {
                    if (res.ok) {
                        editTrackingRequest.value = currentValue;
                        return;
                    }
                    else {
                        if (e.target instanceof HTMLElement) {
                            e.target.innerHTML = editTrackingRequest.value;
                        }
                        toast.GiveErrorToast(yield res.text());
                        throw res;
                    }
                })).catch((err) => __awaiter(this, void 0, void 0, function* () {
                    if (err instanceof Response) {
                        toast.GiveErrorToast(yield err.text());
                    }
                    else {
                        toast.GiveErrorToast('An error occurred.');
                    }
                    throw err;
                }));
            }
        }
    }
    InlineEditService.HandleInlineEdit = HandleInlineEdit;
    function BuildInlineEditRequest(e, value) {
        const id = e.getAttribute('data-id');
        const model = e.getAttribute('data-model');
        const property = e.getAttribute('data-prop');
        let response = {
            id: id,
            modelName: model,
            property: property,
            value: value
        };
        return response;
    }
    InlineEditService.BuildInlineEditRequest = BuildInlineEditRequest;
    function GiveErrorToast(message) {
        const request = new toast.ShowToastRequest(toast.ToastType.error, 'Error!', 0, message);
        toast.ShowToast(request);
    }
    InlineEditService.GiveErrorToast = GiveErrorToast;
    function PostData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch('/InlineEdit', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            return response;
        });
    }
    InlineEditService.PostData = PostData;
})(InlineEditService || (InlineEditService = {}));
