//@ts-ignore
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
export var TimeTools;
(function (TimeTools) {
    function padMe(input) {
        return input.toString().padStart(2, '0');
    }
    function getTimeSegments(timeString) {
        // Split the time string into hours, minutes, and AM/PM
        const [time, ampm] = timeString.split(' ');
        // Split the time into hours and minutes
        const [hours, minutes] = time.split(':').map(Number);
        // Adjust hours for PM times
        const adjustedHours = ampm === 'PM' && hours !== 12 ? hours + 12 : hours;
        return [padMe(adjustedHours), padMe(minutes)];
    }
    function parseOpenDaysString(openDaysString) {
        // Split the string into an array using commas as separators
        var stringArray = openDaysString.split(',');
        // Map each string element to a number
        var numberArray = stringArray.map(function (str) {
            return parseInt(str, 10); // Parse each string as an integer
        });
        return numberArray;
    }
    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    function isOpen(openTimeString, closeTimeString, openDaysString) {
        var currentDate = moment();
        const openDays = parseOpenDaysString(openDaysString);
        const currentDay = currentDate.day();
        if (openDays.indexOf(currentDay) === -1) {
            return false;
        }
        const inanaAnchorTimeZone = 'America/Edmonton';
        const openTimeSegments = getTimeSegments(openTimeString);
        const closeTimeSegments = getTimeSegments(closeTimeString);
        var openTimeString = getCurrentDate() + ' ' + openTimeSegments[0] + ':' + openTimeSegments[1] + ":00";
        var openTimeLocal = moment.tz(openTimeString, inanaAnchorTimeZone).local();
        var closeTimeString = getCurrentDate() + ' ' + closeTimeSegments[0] + ':' + closeTimeSegments[1] + ":00";
        var closeTimeLocal = moment.tz(closeTimeString, inanaAnchorTimeZone).local();
        if (currentDate.isBetween(openTimeLocal, closeTimeLocal, null, '[]')) {
            return true;
        }
        return false;
    }
    function SetOpenStatusFor(context) {
        const openTimeElement = document.getElementById(context + 'OpenTimeString');
        const closeTimeElement = document.getElementById(context + 'CloseTimeString');
        const openDaysElement = document.getElementById(context + 'OpenDaysString');
        const openTextElement = document.getElementById(context + 'OpenMessage');
        const closedTextElement = document.getElementById(context + 'ClosedMessage');
        const openIndicators = document.querySelectorAll("." + context + 'OpenIndicator');
        if (openTimeElement instanceof HTMLInputElement
            && closeTimeElement instanceof HTMLInputElement
            && openDaysElement instanceof HTMLInputElement
            && openTextElement instanceof HTMLInputElement
            && closedTextElement instanceof HTMLInputElement
            && openIndicators instanceof NodeList) {
            const openTime = openTimeElement.value;
            const closeTime = closeTimeElement.value;
            const openMessage = openTextElement.value;
            const closedMessage = closedTextElement.value;
            const openDaysString = openDaysElement.value;
            const officeOpen = isOpen(openTime, closeTime, openDaysString);
            const openText = openMessage;
            const closedText = closedMessage;
            openIndicators.forEach((openIndicator) => {
                openIndicator.className = '';
                if (officeOpen) {
                    openIndicator.className = 'open';
                    openIndicator.innerHTML = openText;
                }
                else {
                    openIndicator.className = 'closed';
                    openIndicator.innerHTML = closedText;
                }
            });
        }
    }
    TimeTools.SetOpenStatusFor = SetOpenStatusFor;
})(TimeTools || (TimeTools = {}));
