var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Offcanvas } from '../../mdb-ui-kit-cherry-pick';
import JustValidate, { Rules } from 'just-validate';
export var CommunicationService;
(function (CommunicationService) {
    const contactOffcanvasElement = document.getElementById('contactRequestOffcanvas');
    const quoteOffcanvasElement = document.getElementById('quoteRequestOffcanvas');
    let contactFormValidator;
    let quoteFormValidator;
    const apiSendCommunicationRequest = (request, endpoint) => fetch("/comm/" + endpoint, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return true;
    });
    function SendContactRequest(formElementId) {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById(formElementId);
            if (form instanceof HTMLFormElement) {
                if (!isContactFormValid(formElementId)) {
                    console.log('contact form is invalid');
                    return;
                }
                var entries = Object.fromEntries(new FormData(form).entries());
                let request = {
                    agreesToOtherContact: !!entries.AgreesToOtherContact,
                    email: entries.Email,
                    message: entries.Message,
                    name: entries.Name,
                    phone: entries.Phone,
                    targetGroup: Number.parseInt(entries.TargetGroup)
                };
                apiSendCommunicationRequest(request, 'contact-request')
                    .then(requestCompleted => {
                    if (requestCompleted) {
                        const offcanvas = Offcanvas.getOrCreateInstance(contactOffcanvasElement);
                        offcanvas.toggle();
                    }
                });
            }
        });
    }
    CommunicationService.SendContactRequest = SendContactRequest;
    function SendQuoteRequest(formElementId) {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById(formElementId);
            if (form instanceof HTMLFormElement) {
                if (!isQuoteFormValid(formElementId)) {
                    quoteFormValidator.validate(true);
                    console.log('quote form is invalid');
                    return;
                }
                var entries = Object.fromEntries(new FormData(form).entries());
                let request = {
                    agreesToOtherContact: !!entries.AgreesToOtherContact,
                    company: entries.Company,
                    email: entries.Email,
                    message: entries.Message,
                    name: entries.Name,
                    phone: entries.Phone,
                    productId: entries.ProductId,
                    timeFrame: Number.parseInt(entries.TimeFrame)
                };
                apiSendCommunicationRequest(request, 'quote-request')
                    .then(requestCompleted => {
                    if (requestCompleted) {
                        const offcanvas = Offcanvas.getOrCreateInstance(quoteOffcanvasElement);
                        offcanvas.toggle();
                    }
                });
            }
        });
    }
    CommunicationService.SendQuoteRequest = SendQuoteRequest;
    function isContactFormValid(formElementId) {
        var form = document.getElementById(formElementId);
        if (form instanceof HTMLFormElement) {
            contactFormValidator = contactFormValidator || new JustValidate('#' + formElementId, { validateBeforeSubmitting: true });
            contactFormValidator.addField('#Name', [
                {
                    rule: Rules.Required,
                    errorMessage: 'Name is required',
                }
            ]);
            contactFormValidator.addField('#Email', [
                {
                    rule: Rules.Required,
                    errorMessage: 'Email is required',
                },
                {
                    rule: Rules.Email,
                    errorMessage: 'Email is invalid!',
                },
            ]);
            return contactFormValidator.isFormValid();
        }
        return false;
    }
    function isQuoteFormValid(formElementId) {
        var form = document.getElementById(formElementId);
        if (form instanceof HTMLFormElement) {
            quoteFormValidator = quoteFormValidator || new JustValidate('#' + formElementId, { validateBeforeSubmitting: true });
            quoteFormValidator.addField('#Name', [
                {
                    rule: Rules.Required,
                    errorMessage: 'Name is required',
                }
            ]);
            quoteFormValidator.addField('#Email', [
                {
                    rule: Rules.Required,
                    errorMessage: 'Email is required',
                },
                {
                    rule: Rules.Email,
                    errorMessage: 'Email is invalid!',
                },
            ]);
            return quoteFormValidator.isFormValid();
        }
        return false;
    }
    isQuoteFormValid('#quoteRequestForm');
    isContactFormValid('#contactRequestForm');
    const elements = document.querySelectorAll('[uhe-action="quote-request"]');
    elements.forEach(key => {
        key.addEventListener('click', (x) => {
            const target = x.target;
            if (target instanceof HTMLElement) {
                const formElementId = target.getAttribute('data-mdb-target');
                console.log(formElementId);
                const form = document.querySelector(formElementId);
                console.log(form);
                const productIdInput = form.querySelector('#ProductId');
                productIdInput.value = target.getAttribute('uhe-data-productid');
                const offcanvas = Offcanvas.getOrCreateInstance(quoteOffcanvasElement);
                offcanvas.toggle();
            }
        });
    });
})(CommunicationService || (CommunicationService = {}));
