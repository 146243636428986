export var MdbSuck;
(function (MdbSuck) {
    function FixFormControlLabelDyanmicUpdateIssue() {
        document.querySelectorAll('input, textarea').forEach((a) => {
            if (a.classList.contains('form-control') && a.value) {
                a.classList.add('active');
            }
        });
    }
    MdbSuck.FixFormControlLabelDyanmicUpdateIssue = FixFormControlLabelDyanmicUpdateIssue;
})(MdbSuck || (MdbSuck = {}));
