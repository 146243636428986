export var EmployeeRoles;
(function (EmployeeRoles) {
    EmployeeRoles[EmployeeRoles["None"] = 0] = "None";
    EmployeeRoles[EmployeeRoles["Sales"] = 1] = "Sales";
    EmployeeRoles[EmployeeRoles["Inside"] = 2] = "Inside";
    EmployeeRoles[EmployeeRoles["Outside"] = 4] = "Outside";
    EmployeeRoles[EmployeeRoles["Service"] = 8] = "Service";
    EmployeeRoles[EmployeeRoles["Parts"] = 16] = "Parts";
    EmployeeRoles[EmployeeRoles["GeneralManager"] = 32] = "GeneralManager";
})(EmployeeRoles || (EmployeeRoles = {}));
export var DistributionGroups;
(function (DistributionGroups) {
    DistributionGroups[DistributionGroups["None"] = 0] = "None";
    DistributionGroups[DistributionGroups["Sales"] = 1] = "Sales";
    DistributionGroups[DistributionGroups["Parts"] = 2] = "Parts";
    DistributionGroups[DistributionGroups["Service"] = 4] = "Service";
})(DistributionGroups || (DistributionGroups = {}));
