export var LinkUX;
(function (LinkUX) {
    function LinkClick(e) {
        const sessionEditing = sessionStorage.getItem('uhe-web-editing');
        const linkTarget = e.getAttribute('data-href');
        if (sessionEditing != 'on') {
            console.log('fired');
            window.location.href = linkTarget;
        }
    }
    LinkUX.LinkClick = LinkClick;
})(LinkUX || (LinkUX = {}));
