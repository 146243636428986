export var QuoteRequestUX;
(function (QuoteRequestUX) {
    function Initialize() {
        const toggleQuoteRequest = function () {
            const hostQR = document.getElementById('QuoteRequestHost');
            if (hostQR != null) {
                hostQR.classList.toggle('d-none');
            }
        };
        document.addEventListener('click', function (e) {
            if (e.target instanceof HTMLElement) {
                if (e.target && (e.target.id == 'quoterequest' || e.target.id == 'ComRequestClose')) {
                    toggleQuoteRequest();
                }
            }
        });
    }
    QuoteRequestUX.Initialize = Initialize;
})(QuoteRequestUX || (QuoteRequestUX = {}));
