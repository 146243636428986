var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastUX } from "../../ux/ToastUX";
export var CommonService;
(function (CommonService) {
    const toast = ToastUX;
    function Delete(e) {
        const title = e.getAttribute('data-webobject-title');
        const slug = e.getAttribute('data-webobject-slug');
        const url = e.getAttribute('data-upload-uri');
        let result = confirm('This will permanently delete all images, document, and slugs assocaited with ' + title + '.\n\ncontinue?');
        if (result === true) {
            let formData = new FormData();
            formData.append('DefaultSlug', slug);
            doDelete(url, formData);
        }
    }
    CommonService.Delete = Delete;
    function doDelete(url, formData) {
        return __awaiter(this, void 0, void 0, function* () {
            fetch(url, {
                method: 'DELETE',
                body: formData
            })
                .then(res => {
                location.reload();
            })
                .catch((err) => __awaiter(this, void 0, void 0, function* () {
                if (err instanceof Response) {
                    toast.GiveErrorToast(yield err.text());
                }
                else {
                    toast.GiveErrorToast('An error occurred.');
                }
                throw err;
            }));
        });
    }
})(CommonService || (CommonService = {}));
