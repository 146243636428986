import { FileService } from './api/services/FileUploadService';
import { ImageService } from './api/services/ImageService';
import { DocumentService } from './api/services/DocumentService';
import { InlineEditService } from './api/services/InlineEditService';
import { CarouselUX } from './ux/CarouselUX';
import { NavLocationUX } from './ux/NavLocationUX';
import { QuoteRequestUX } from './ux/QuoteRequestUX';
import { ProductService } from './api/services/ProductService';
import { LinkUX } from './ux/LinkUX';
import { ImageTools } from './ux/ImageTools';
import { CommonService } from './api/services/CommonService';
import { MapService } from './api/services/MapService';
import { EmployeeService } from './api/services/EmployeeService';
import { MdbSuck } from './shims';
import { CommunicationService } from './api/services/CommunicationService';
import { TimeTools } from './common/TimeTools';
import { CompanyService } from './api/services/CompanyService';
NavLocationUX.Initialize();
QuoteRequestUX.Initialize();
CarouselUX.Initialize();
InlineEditService.Initialize("authorizedEditor");
ImageTools.Initialize();
MapService.Initialize();
MdbSuck.FixFormControlLabelDyanmicUpdateIssue();
document.addEventListener('shown.bs.offcanvas', function () {
    MdbSuck.FixFormControlLabelDyanmicUpdateIssue();
});
document.addEventListener('scroll', () => {
    const st = window.scrollY;
    if (st > 150) {
        playOrPauseIntroVideo(false);
    }
    else {
        playOrPauseIntroVideo();
    }
});
const videoBox = document.querySelector('.video-box');
//prevent exception if videoBox is not present
const video = videoBox === null || videoBox === void 0 ? void 0 : videoBox.querySelector('video');
function playOrPauseIntroVideo(shouldPlay = true) {
    if (video instanceof HTMLVideoElement) {
        if (shouldPlay) {
            video.play();
        }
        else {
            video.pause();
        }
    }
}
document.addEventListener('DOMContentLoaded', () => {
    playOrPauseIntroVideo();
});
TimeTools.SetOpenStatusFor('Office');
TimeTools.SetOpenStatusFor('Receiving');
const app = {
    FileService: FileService,
    ImageService: ImageService,
    DocumentService: DocumentService,
    ProductService: ProductService,
    LinkUX: LinkUX,
    ImageTools: ImageTools,
    CommonService: CommonService,
    EmployeeService: EmployeeService,
    CommunicationService: CommunicationService,
    CompanyService: CompanyService
};
export default app;
