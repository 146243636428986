var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var FileService;
(function (FileService) {
    function FileUpload(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = e.closest('div').querySelector('form');
            console.log(formElement);
            const elFile = formElement.querySelector('input[type="file"]');
            console.log(elFile);
            if (elFile instanceof HTMLInputElement) {
                elFile.addEventListener('change', SendFile);
                elFile.click();
            }
        });
    }
    FileService.FileUpload = FileUpload;
    function SendFile(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e.target instanceof HTMLInputElement) {
                const formElement = e.target.form;
                const formData = new FormData(formElement);
                const response = yield fetch(formElement.action, {
                    method: 'POST',
                    body: formData
                });
                location.reload();
            }
        });
    }
    FileService.SendFile = SendFile;
})(FileService || (FileService = {}));
