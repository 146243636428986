export var Utilities;
(function (Utilities) {
    let timeout;
    Utilities.debounce = (func, waitFor) => {
        return (...args) => new Promise(resolve => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => resolve(func(...args)), waitFor);
        });
    };
})(Utilities || (Utilities = {}));
