export var NavLocationUX;
(function (NavLocationUX) {
    function Initialize() {
        'use strict';
        const nvroot = document.getElementById('navloc_root');
        const nvbranch = document.getElementById('navloc_branch');
        if (nvroot != null && (!!nvroot || !!nvbranch)) {
            const navitem = document.querySelector('[data-nvloc="' + nvroot.getAttribute('value') + '"]');
            if (!!navitem) {
                navitem.classList.add('active');
            }
        }
        const toggleQuoteRequest = function () {
            const hostQR = document.getElementById('QuoteRequestHost');
            if (hostQR != null) {
                hostQR.classList.toggle('d-none');
            }
        };
        document.addEventListener('click', function (e) {
            if (e.target instanceof HTMLElement) {
                if (e.target && (e.target.id == 'quoterequest' || e.target.id == 'ComRequestClose')) {
                    toggleQuoteRequest();
                }
            }
        });
    }
    NavLocationUX.Initialize = Initialize;
})(NavLocationUX || (NavLocationUX = {}));
