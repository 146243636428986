var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var DocumentService;
(function (DocumentService) {
    function doDelete(e) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const el = e;
            const uri = (_a = el.getAttribute('data-actionuri')) !== null && _a !== void 0 ? _a : '';
            const response = yield fetch(uri.toString(), {
                method: 'DELETE'
            }).then(res => {
                if (res.ok) {
                    location.reload();
                }
            });
        });
    }
    function DeleteDocument(elem) {
        const yes = confirm('Permanently delete this document?');
        if (yes) {
            doDelete(elem);
        }
    }
    DocumentService.DeleteDocument = DeleteDocument;
})(DocumentService || (DocumentService = {}));
