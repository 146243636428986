var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastUX } from '../../ux/ToastUX';
export var ProductService;
(function (ProductService) {
    const toast = ToastUX;
    function CreateClass(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e instanceof HTMLElement) {
                HandleEditorRequest('productClassEditorForm');
            }
        });
    }
    ProductService.CreateClass = CreateClass;
    function CreateGroup(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e instanceof HTMLElement) {
                HandleEditorRequest('productGroupEditorForm');
            }
        });
    }
    ProductService.CreateGroup = CreateGroup;
    function CreateItem(e) {
        return __awaiter(this, void 0, void 0, function* () {
            if (e instanceof HTMLElement) {
                HandleEditorRequest('productItemEditorForm');
            }
        });
    }
    ProductService.CreateItem = CreateItem;
    function HandleEditorRequest(formElementId) {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById(formElementId);
            if (formElement instanceof HTMLFormElement) {
                const formData = new FormData(formElement);
                const response = yield fetch(formElement.action, {
                    method: 'POST',
                    body: formData
                }).then((res) => {
                    if (res.ok) {
                        location.reload();
                    }
                    else {
                        res.text().then((text) => {
                            toast.GiveErrorToast(text);
                        });
                    }
                }).catch(err => {
                    err.text().then((text) => {
                        toast.GiveErrorToast(text);
                        throw err;
                    });
                });
            }
        });
    }
})(ProductService || (ProductService = {}));
