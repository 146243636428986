import { ToastUX } from './ToastUX';
export var DropArea;
(function (DropArea) {
    const toast = ToastUX;
    let dropArea;
    let openImageFileInput;
    let handleFileCallBack;
    function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    function highlight(e) {
        dropArea.classList.add('highlight');
    }
    function unhighlight(e) {
        dropArea.classList.remove('highlight');
    }
    function handleFilelOpen() {
        if (openImageFileInput instanceof HTMLInputElement
            && openImageFileInput.files.length > 0) {
            var file = openImageFileInput.files[0];
            handleFileCallBack(file);
        }
    }
    function handleDrop(e) {
        let dt = e.dataTransfer;
        if (dt.files.length > 1) {
            toast.GiveErrorToast('Only one image file at a time is allowed.');
            return;
        }
        let file = dt.files[0];
        if (!file.type.match('image.*')) {
            toast.GiveErrorToast('Only image files may be dropped here.');
            return;
        }
        handleFileCallBack(file);
    }
    function intializeDropArea() {
        if (dropArea instanceof HTMLElement) {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, preventDefaults, false);
            });
            ['dragenter', 'dragover'].forEach(eventName => {
                dropArea.addEventListener(eventName, highlight, false);
            });
            ['dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, unhighlight, false);
            });
            dropArea.addEventListener('drop', handleDrop, false);
        }
    }
    function intializeFileOpener() {
        openImageFileInput = document.createElement('input');
        openImageFileInput.type = 'file';
        openImageFileInput.accept = 'image/*';
        openImageFileInput.addEventListener('change', handleFilelOpen);
        let clickTarget = dropArea.querySelector('#file-dialog-opener');
        if (clickTarget instanceof HTMLAnchorElement) {
            clickTarget.addEventListener('click', function (e) {
                e.preventDefault();
                openImageFileInput.click();
            });
        }
    }
    function Initialize(handleFile, dropAreaElement) {
        handleFileCallBack = handleFile;
        if (handleFileCallBack instanceof Function
            && dropAreaElement instanceof HTMLElement) {
            dropArea = dropAreaElement;
            intializeDropArea();
            intializeFileOpener();
        }
    }
    DropArea.Initialize = Initialize;
})(DropArea || (DropArea = {}));
