import { Toast } from '../mdb-ui-kit-cherry-pick';
export var ToastUX;
(function (ToastUX) {
    let ToastType;
    (function (ToastType) {
        ToastType["error"] = "error";
        ToastType["info"] = "info";
        ToastType["success"] = "success";
    })(ToastType = ToastUX.ToastType || (ToastUX.ToastType = {}));
    class ShowToastRequest {
        constructor(toasType = ToastType.info, title = '', whenMinutesAgo = 0, message = '') {
            this.toastType = toasType;
            this.title = title;
            this.whenMinutesAgo = whenMinutesAgo;
            this.message = message;
        }
    }
    ToastUX.ShowToastRequest = ShowToastRequest;
    function GetToastElementName(toastType) {
        return 'toast-ux-' + toastType;
    }
    function ShowToast(toastRequest) {
        const toastId = GetToastElementName(toastRequest.toastType);
        const toastElement = document.getElementById(toastId);
        AppyToastRequest(toastElement, toastRequest);
        const toast = Toast.getInstance(toastElement);
        toast.show();
    }
    ToastUX.ShowToast = ShowToast;
    function AppyToastRequest(element, toastRequest) {
        const title = element.querySelector('[toast-title]');
        const when = element.querySelector('[toast-when]');
        const message = element.querySelector('[toast-message]');
        title.innerHTML = toastRequest.title;
        when.innerHTML = toastRequest.whenMinutesAgo + ' mins ago';
        message.innerHTML = toastRequest.message;
    }
    function GiveErrorToast(message) {
        const request = new ShowToastRequest(ToastType.error, 'Error!', 0, message);
        ShowToast(request);
    }
    ToastUX.GiveErrorToast = GiveErrorToast;
})(ToastUX || (ToastUX = {}));
