import { Carousel } from "../mdb-ui-kit-cherry-pick";
export var CarouselUX;
(function (CarouselUX) {
    const uheCarousel = document.getElementById('uheImageCarousel');
    const uheIndicatorHost = document.getElementsByClassName('uhe-carousel-indicators');
    const uheIndicatorSet = (uheIndicatorHost != undefined && uheIndicatorHost.length > 0) ? uheIndicatorHost[0].children : null;
    let carousel;
    let preventResume;
    function UpdateIndicators(currentImg) {
        for (var i = 0; i < uheIndicatorSet.length; i++) {
            const ind = uheIndicatorSet[i];
            if (parseInt(ind.getAttribute('data-mdb-slide-to')) === parseInt(currentImg)) {
                ind.classList.add('active');
            }
            else {
                ind.classList.remove('active');
            }
        }
    }
    function EnforcePause() {
        if (preventResume) {
            carousel.pause();
        }
    }
    function Initialize() {
        preventResume = false;
        if (uheCarousel == undefined || uheCarousel == null) {
            return;
        }
        uheCarousel.addEventListener('slide.bs.carousel', function (a) {
            UpdateIndicators(a.to);
            EnforcePause();
        });
        uheCarousel.addEventListener('mouseout', function (a) {
            EnforcePause();
        });
        for (var i = 0; i < uheIndicatorSet.length; i++) {
            const ind = uheIndicatorSet[i];
            ind.addEventListener('mouseout', function (a) {
                EnforcePause();
            });
            ind.addEventListener('click', function (a) {
                EnforcePause();
            });
        }
        carousel = Carousel.getOrCreateInstance(uheCarousel);
    }
    CarouselUX.Initialize = Initialize;
    function PauseCarousel() {
        if (carousel == undefined || carousel == null) {
            return;
        }
        preventResume = true;
        carousel.pause();
        for (var i = 0; i < uheIndicatorSet.length; i++) {
            const ind = uheIndicatorSet[i];
            ind.classList.add('active');
        }
    }
    CarouselUX.PauseCarousel = PauseCarousel;
    function PlayCarousel() {
        if (carousel == undefined || carousel == null) {
            return;
        }
        preventResume = false;
        carousel.cycle();
    }
    CarouselUX.PlayCarousel = PlayCarousel;
})(CarouselUX || (CarouselUX = {}));
